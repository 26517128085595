import { FC, MouseEvent, SVGProps } from "react";
import { Link } from "react-router-dom";
import { useAuthentication } from "@smartship-services/core/hooks";
import {
  AdjustmentsVerticalIcon,
  ArrowDownTrayIcon,
  ArrowLeftEndOnRectangleIcon,
  EllipsisHorizontalIcon,
  XMarkIcon,
} from "@heroicons/react/24/outline";
import { ShipIcon } from "../../assets/icons";

type MenuItem = {
  icon: FC<SVGProps<SVGSVGElement>>;
  text: string;
  linkProps: { to: string };
};

export const CollapsableMenu: FC = () => {
  const { logout } = useAuthentication();

  const { currentUser } = useAuthentication();
  const canImportVessel = currentUser?.permissions.includes("vessels:write");

  const Items = [
    {
      icon: AdjustmentsVerticalIcon,
      text: "Settings",
      linkProps: { to: "/settings" },
    },
    canImportVessel && {
      icon: ArrowDownTrayIcon,
      text: "Import vessel",
      linkProps: { to: "/vessels/import" },
    },
    {
      icon: ShipIcon,
      text: "Choose active vessel",
      linkProps: { to: "/vessels/active" },
    },
  ].filter(Boolean) as MenuItem[];

  const closeMenu = (e: MouseEvent<HTMLAnchorElement | HTMLButtonElement>) => {
    e.currentTarget?.closest("details")?.removeAttribute("open");
  };

  return (
    <details className="dropdown dropdown-top lg:dropdown-bottom dropdown-end">
      <summary className="btn w-full h-full relative bg-base-100 border-none">
        <EllipsisHorizontalIcon className="absolute -top-2 h-14 w-14 text-primary" />
        <span className="absolute top-8 text-xs font-thin normal-case">
          More
        </span>
      </summary>
      <ul className="menu dropdown-content z-[1] bg-base-100 w-64 p-0 shadow-md font-medium text-secondary text-xs border-collapse">
        {Items.map((item) => (
          <li key={item.text} className="border border-neutral uppercase">
            <Link {...item.linkProps} onClick={closeMenu} className="p-2">
              <item.icon className="icon-size-default" />
              {item.text}
            </Link>
          </li>
        ))}
        <li className="border border-neutral flex flex-row">
          <button onClick={logout} className="p-2 grow">
            <ArrowLeftEndOnRectangleIcon className="icon-size-default" />
            LOGOUT
          </button>
          <button
            onClick={closeMenu}
            aria-label="Close menu"
            className="border-l border-l-neutral p-2 hover:bg-neutral"
          >
            <XMarkIcon className="icon-size-default" />
          </button>
        </li>
      </ul>
    </details>
  );
};
